.autocomplete-suggestions {
    display: none;
    max-height: 254px;
    overflow: hidden;
    overflow-y: auto;
    position: absolute;
    z-index: layer(modal);
}
.autocomplete-suggestion {
    cursor: pointer;
    overflow: hidden;
    position: relative;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.autocomplete-suggestion.selected {
    color: map-get($base__colors, 'white');
    background-color: map-get($base__colors, 'fall');
}