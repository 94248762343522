.figure {
    position: relative;
}
.figure__caption {
    bottom: 0;
    padding: {
        right: space--s();
        left: space--s();
    }
    position: absolute;
    width: 100%;
}
