@keyframes map__show {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
@keyframes map__pulsate {
    0%,
    25% {
        opacity: 1;
    }
    50% {
        opacity: .5;
    }
    75%,
    100% {
        opacity: 1;
    }
}
.map:not(.is-active) {
    position: relative;
    
    &::before {
        @include typo--head(3);
        @include middle(true);
        animation: map__show 1s 1s, map__pulsate 3s 2s infinite;
        content: attr(data-map-loading);
        opacity: 0;
    }
}