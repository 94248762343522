$layers: (
  "top": 9000,
  "modal": 8000,
  "super": 5000,
  "above": 1000,
  "default": 1,
  "background": -1
);

@function layer($layer) {
    @if not map-has-key($layers, $layer) {
        @warn "No layer found for `#{$layer}` in $layers map. Property omitted.";
    }
  
    @return map-get($layers, $layer);
}
