@include media(m-to) {
    .drawer {
        position: relative;
    }
    .drawer__item {
        background-color: $base__background-color;
        position: fixed;
        top: 0;
        bottom: 0;
        right: 0;
        width: 100vw;
        overflow-y: auto;
        -webkit-overflow-scrolling: touch;
        transition: transform 250ms;
        z-index: layer(modal);
        max-width: 2 / 3 * map-get($map: $base__breakpoint, $key: 's');
        
        &:not(.is-active) {
            transform: translateX(100%);
        }
        &.is-active {
            box-shadow: space--s(-1) 0 space--s() 0 rgba($base__color, .25);
        }
    }
    .drawer__button--open {
        position: absolute;
        top: 0;
        right: 0;
    }
    .drawer__button--close {
        position: fixed;
        transform: translateX(-100%);
        z-index: layer(modal);
    }
}
@include media(l-up) {
    .drawer__button {
        display: none;
    }
}