.configurator__label {
    cursor: pointer;
}
.configurator__images_container {
    position: sticky;
    top: 54px;
}
.configurator__images {
    position: relative;
}
.configurator__image {
    position: absolute;
        left: 0;
        bottom: 0;
    transition: opacity 350ms;
    
    &:not(.is-active) {
        opacity: 0;
    }
}
.configurator__image__caption {
    padding: {
        right: space--s();
        left: space--s();
    }
    position: absolute;
        bottom: 0;
        width: 100%;
}
