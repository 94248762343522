.sorter {
    position: relative;
}
.sorter::before {
    content: "• ";
    left: space--s(1/2);
    position: absolute;
    transition: opacity 250ms;
}
:not(:checked) + .sorter::before {
    opacity: 0;
}
.sorter__icon {
    transition: transform 250ms;
}
.sorter.is-active .sorter__icon {
    transform: scaleY(-1);
}