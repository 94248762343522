//**
//* Centers a block-level element.
//*/
@mixin center($display: block) {
    @if $display {
        display: $display;
    }
    margin: {
        left: auto;
        right: auto;
    }
}