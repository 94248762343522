.u-pad {
    //@include media-generate(".u-pad") {
        @include space(padding-top, $important: true);
    //}
    
    //@include media-generate(".u-pad--n") {
    &--n {
        @include space--n(padding-top, $important: true);
    }
    //@include media-generate(".u-pad--t") {
    &--t {
        @include space--t(padding-top, $important: true);
    }
    //@include media-generate(".u-pad--s") {
    &--s {
        @include space--s(padding-top, $important: true);
    }
    //@include media-generate(".u-pad--m") {
    &--m {
        @include space--m(padding-top, $important: true);
    }
    //@include media-generate(".u-pad--l") {
    &--l {
        @include space--l(padding-top, $important: true);
        
        &.u-pad--bump {
            @include media(h-x) {
                @include space--l(padding-top, $addend: space--l(), $important: true);
            }
        }
    }
    //@include media-generate(".u-pad--x") {
    &--x {
        @include space--x(padding-top, $important: true);
        
        &.u-pad--bump {
            @include media(h-x) {
                @include space--x(padding-top, $addend: space--l(), $important: true);
            }
        }
    }
    //@include media-generate(".u-pad--h") {
    &--h {
        @include space--h(padding-top, $important: true);
        
        &.u-pad--bump {
            @include media(h-x) {
                @include space--h(padding-top, $addend: space--l(), $important: true);
            }
        }
    }
}