@keyframes loader__is-active {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
.loader {
    @include box();
    position: relative;
    
    &::before {
        background-color: map-get($base__colors--overlay, 'steel');
        content: "";
        display: block;
        opacity: 0;
        position: absolute;
            height: 100%;
            width: 100%;
            top: 0;
        z-index: layer(background);
    }
    
    &.is-active {
        &::before {
            animation: loader__is-active 250ms 1s forwards;
            z-index: layer(top);
        }
    }
}