.logo {
    width: auto;
    height: 52px;
    display: block;

    @include media(m) {
        height: 60px;
    }
    @include media(l-up) {
        height: 48px;
    }
}
