.blockquote {
    @include reset(blockquote);
    @include space--s(padding-right padding-left);
    
    &::before,
    &::after {
        border: 0 solid transparent;
        content: "";
        display: block;
        height: 0;
        width: 0;
    }
    &::before {
        margin-left: space--s(-1);
        border: {
            top-width: space--l();
            top-color: map-get($base__colors, 'fall');
            right-width: space--l();
        }
    }
    &::after {
        margin-left: auto;
        margin-right: space--s(-1);
        border: {
            bottom-width: space--l();
            bottom-color: map-get($base__colors, 'fall');
            left-width: space--l();
        }
    }
}
