$c-button__border-width: 6px;
$c-button__border-width--s: 4px;
$c-button__padding-bump: 0;
$c-button__padding-bump--s: -6px;
$c-button__padding-bump--l: 3px;
$c-button__padding-bump--x: (6px 7px);
.button {
    $_text-color: contrasting-color(map-get($base__colors, 'light-gray'), map-get($base__colors, 'white'), $base__color);
    
    @include reset(a button);
    @include typo--body(s);
    
    background-color: map-get($base__colors, 'light-gray');
    border-bottom: $c-button__border-width solid transparent;
    display: inline-block;
    font-weight: map-get($base__font-weights, bold);
    line-height: inherit;
    padding: $c-button__border-width + $c-button__padding-bump space() $c-button__padding-bump;
    text-align: left;
    text-transform: uppercase;
    transition: {
        duration: 250ms;
        property: border-bottom-color, opacity;
    };
    
    &,
    &:link,
    &:visited {
        color: $_text-color;
        cursor: pointer;
        &:not(:disabled) {
            &:hover,
            &:focus {
                border-bottom-color: map-get($base__colors--overlay, 'magnesium');
            }
        }
    }
    
    &:disabled {
        opacity: .5;
    }
    
    &.is-active {
        background-color: mix(map-get($base__colors, 'light-gray'), black, 75%);
    }

    @include media(s) {
        font-size: $base__font-size--small - 1;
    }
}
.button--inline {
    border-bottom-width: 0;
    display: inline;
    font-size: inherit;
    font-variant: small-caps;
    line-height: inherit;
    padding: 0 space--s(1/4);
    text-transform: lowercase;
}
.button--simple {
    font-size: 13px;
    transition-property: color, opacity;
    
    &:not(:disabled) {
        &:hover,
        &:focus {
            border-bottom-color: transparent;
        }
    }
}
.button--overlay {
    background-color: map-get($base__colors--overlay, 'steel');
    color: map-get($base__colors, 'white');
    transition-property: border-bottom-color, background-color, opacity;
    
    &,
    &:link,
    &:visited {
        &:not(:disabled) {
            &:hover,
            &:focus {
                border-bottom-color: map-get($base__colors--overlay, 'charcoal');
            }
        }
    }
    &.is-active {
        background-color: map-get($base__colors--overlay, 'charcoal');
    }
}
.button--s {
    border-bottom-width: $c-button__border-width--s;
    padding: {
        bottom: space--s($addend: $c-button__padding-bump--s - $c-button__border-width);
        left: space--s();
        right: space--s();
        top: space--s($addend: $c-button__padding-bump--s);
    }
}
.button--l {
    padding: {
        bottom: space--s($addend: $c-button__padding-bump--l - $c-button__border-width);
        top: space--s($addend: $c-button__padding-bump--l);
    }
}
.button--x {
    padding: {
        bottom: space--s($addend: nth($c-button__padding-bump--x, 1) - $c-button__border-width);
        top: space--s($addend: nth($c-button__padding-bump--x, 1));
    }
    @include media(l-up) {
        padding: {
            bottom: space--s($addend: nth($c-button__padding-bump--x, 2) - $c-button__border-width);
            top: space--s($addend: nth($c-button__padding-bump--x, 2));
        }
    }
}
.button--i {
    background-color: transparent;
    border-bottom: 0;
    padding: 0;
}
@each $_name, $_color in $base__colors {
    $_text-color: contrasting-color($_color, map-get($base__colors, 'white'), $base__color);
    .button--c-#{$_name} {
        background-color: $_color;
        &,
        &:link,
        &:visited {
            color: $_text-color;
        }
        &.is-active {
            background-color: mix($_color, black, 75%);
        }
        &.button--simple {
            &:not(:disabled) {
                &:not(:hover):not(:focus) {
                    color: rgba($_text-color, .7);
                }
            }
        }
    }
    .button--i-#{$_name} {
        color: $_color;
    }
    .button--b-#{$_name} {
        border-bottom-color: $_color;
    }
}
