@mixin curtain {
    overflow: hidden;
    will-change: height;
    
    &.is-hidden:not(.curtain--no-hidden) {
        display: none;
    }
    
    &:not(.is-transitioned) {
        height: 0;
    }
    
    &.has-transitions {
        transition: height 250ms;
    }
    
    &:focus {
        outline: none;
    }
}

@include media-generate('.curtain') {
    @include curtain;
}

.curtain__head {
    cursor: pointer;
}
button.curtain__head {
    width: 100%;
    display: block;
    text-align: left;
}
.curtain__head--r {
    text-align: right;
}
.curtain__icon {
    transition: transform 250ms;
}
.curtain__head.is-active .curtain__icon {
    transform: rotate(45deg);
}
.curtain__head.is-active .curtain__icon--mirror {
    transform: rotate(180deg);
}