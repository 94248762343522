.veillay {
    @include box();
}
@each $_name, $_color in $base__colors {
    .veillay--c-#{$_name} {
        background-color: rgba($_color, .1);
    }
}
@each $_name, $_color in $base__colors--veillay {
    .veillay--c-#{$_name} {
        background-color: $_color;
    }
}