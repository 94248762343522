$c-scroll-nav__border--width: 3px;
$c-scroll-nav__border--color: map-get($base__colors, 'light-gray');
$c-scroll-nav__z-index: layer('super');

.scroll-nav__bar {
    background-color: map-get($base__colors--veillay, 'white');
    border-bottom: $c-scroll-nav__border--width solid $c-scroll-nav__border--color;
    position: sticky;
        top: 0;
        z-index: $c-scroll-nav__z-index;
}
.scroll-nav {
    @include media(m-to) {
        display: none;
    }
    @include media(l-up) {
        margin-bottom: space--t(-1, 2);
    }
}
.scroll-nav__section {
    .grid.grid--gutter.grid--colend > & {
        @include grid(gutter colend);
    }
}
.scroll-nav__actions {
    @include media(m-to) {
        padding-top: space--s();
        padding-bottom: space--s();
    }
    @include media(l-up) {
        padding-top: space--s() - 1px;
        padding-bottom: space--s() - 1px;
    }
}
.scroll-nav__list {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
    
    @include media(m-up) {
        @include grid(gutter);
    }
}
.scroll-nav__link {
    @include typo--body(s);
    @include space--s(padding-top, 3/2);
    @include space--s(padding-bottom, 3/2, -4);
    border-bottom-width: $c-scroll-nav__border--width;
    display: block;
    font-weight: bold;
    text-transform: uppercase;
    
    &,
    &:hover,
    &:focus {
        border-bottom-color: transparent;
    }
    &:focus {
        color: inherit;
    }
    
    @include media(m-up) {
        @include space(padding-top);
        @include space(padding-bottom, $addend: -4);
    }
}
.scroll-nav__item.active .scroll-nav__link {
    @include media(m-up) {
        color: $base__color--focus;
        border-bottom-color: $base__color--focus;
    }
}
