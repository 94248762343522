.box {
    @include box();
    @include space(padding-left padding-right padding-top);
    
    transition-property: color, background-color;
    
    &,
    hr,
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    :link, :visited {
        transition-duration: 250ms;
    }
    
    hr,
    h1, .h1,
    h2, .h2,
    h3, .h3,
    h4, .h4,
    h5, .h5,
    h6, .h6,
    :link, :visited {
        transition-property: color;
    }
    hr {
        transition-property: border-bottom-color;
    }
    
    @include media(m x) {
        @include space--s(padding-bottom);
        @include space--l(padding-left padding-right padding-top);
    }
}

.box__bleed {
    @include space(margin-left margin-right, -1);

    @include media(m x) {
        @include space--l(margin-left margin-right, -1);
    }
}

@include media-generate(".box--c") {
    border: 3px solid map-get($base__colors, 'light-gray');
}

@each $_name, $_color in $base__colors {
    .box--c-#{$_name} {
        border-color: $_color;
        
        &.is-active {
            background-color: $_color;
            
            &,
            h1, .h1,
            h2, .h2,
            h3, .h3,
            h4, .h4,
            h5, .h5,
            h6, .h6,
            :link, :visited {
                color: map-get($base__colors, 'white');
            }
            hr {
                border-bottom-color: map-get($base__colors, 'white');
            }
        }
    }
    .box--c-#{$_name}--dark {
        border-color: mix($_color, black, 75%);
    }
}
