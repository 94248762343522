@viewport {
    zoom: 1.0;
    width: extend-to-zoom;
}


//**
//* Global variables
//*/

// font and spacing

$base__font-size: 17px !default;
$base__font-size--tiny: 12px !default;
$base__font-size--small: 14px !default;
$base__font-size--large: 20px !default;
$base__line-height: 24px !default;
$base__line-height--tiny: 15px !default;
$base__line-height--small: 18px !default;
$base__line-height--large: 28px !default;
$base__space-unit: 20px !default;
$base__font-family: Roboto, Helvetica, Arial, sans-serif !default;
$base__font-family--head: $base__font-family !default;
$base__font-weights: ('normal': normal, 'medium': 500, 'bold': bold) !default;

// colours

$base__colors: (
    'white': #FFFFFF,
    'light-gray': #E6E6E6,
    'mid-gray': #A9A9A9,
    'dark-gray': #575757,
    'charcoal': #333333,
    'black': #000000,

    'fall': #E15242, // new color name 'red'
    'sunshine': #FFB500, // new color name 'gold'
    'winter': #3FB2D2, // new color name 'dark-aqua'
    'ice': #02426D, // new color name 'champion-blue'
) !default;

$base__colors--overlay: (
    'white': rgba(black, .1),
    'iron': rgba(black, .2),
    'magnesium': rgba(black, .3),
    'steel': rgba(black, .6),
    'charcoal': rgba(black, .8),
) !default;

$base__colors--veillay: (
    'black': rgba(white, .1),
    'charcoal': rgba(white, .2),
    'steel': rgba(white, .4),
    'iron': rgba(white, .8),
    'white': rgba(white, .9),
) !default;

$base__color: map-get($base__colors, 'charcoal') !default;
$base__color--head: $base__color !default;
$base__color--link: $base__color !default;
$base__color--focus: map-get($base__colors, 'fall') !default;
$base__background-color: map-get($base__colors, 'white') !default;

// breakpoints

// webflow.com breakpoints
// $base__breakpoint: (
//     s: 479px,
//     m: 767px,
//     l: 991px,
//     x: null,
// ) !default;

$base__breakpoint: (
    s: 500px,
    m: 850px,
    l: 1300px,
    x: null,
) !default;

// NOTE: a breakpoint with true as a second value means the style will be included but not wrapped in any media query

$base__breakpoints: (
    s:    '(max-width: ' + (map-get($base__breakpoint, s)) + ')',
    m-up: '(min-width: ' + (map-get($base__breakpoint, s) + 1) + ')',
    m-to: '(max-width: ' + (map-get($base__breakpoint, m)) + ')',
    m:    '(min-width: ' + (map-get($base__breakpoint, s) + 1) + ') and (max-width: ' + (map-get($base__breakpoint, m)) + ')',
    l-up: '(min-width: ' + (map-get($base__breakpoint, m) + 1) + ')',
    l-to: '(max-width: ' + (map-get($base__breakpoint, l)) + ')',
    l:    '(min-width: ' + (map-get($base__breakpoint, m) + 1) + ') and (max-width: ' + (map-get($base__breakpoint, l)) + ')',
    x:    '(min-width: ' + (map-get($base__breakpoint, l) + 1) + ')',
    
    h-m:  '(min-height: ' + (map-get($base__breakpoint, s) + 1) + ')',
    h-l:  '(min-height: ' + (map-get($base__breakpoint, m) + 1) + ')',
    h-x:  '(min-height: ' + (map-get($base__breakpoint, l) + 1) + ')'
) !default;
