.pac:not(#\#) {
    position: relative;

    .pac-container {
        left: 0 !important;
        top: auto !important;
        width: 100% !important;

        background-color: map-get($base__colors, 'white');
        border-radius: 0;
        border-top-color: map-get($base__colors--overlay, 'iron');
        border: $c-form__border-width solid map-get($base__colors, 'fall');
        box-shadow: none;
        font-family: inherit;
        margin-top: -$c-form__border-width;
    
        &,
        & > * {
            padding: space--s(1/2, $c-form__padding-bump - $c-form__border-width) space(1/2, $c-form__padding-bump - $c-form__border-width);
        }
    }

    .pac-icon {
        display: none;
    }
    
    .pac-item {
        border-top: none;
        color: inherit;
        cursor: pointer;
        font-size: inherit;
        line-height: inherit;
    }
    
    .pac-item-query {
        font-size: inherit;
        line-height: inherit;
    }
    
    .pac-item:hover {
        background-color: map-get($base__colors, 'light-gray');
    }
    
    .pac-item-selected {
        color: map-get($base__colors, 'white');
    
        &, &:hover {
            background-color: map-get($base__colors, 'fall');
        }
    }
    
    .pac-item:hover,
    .pac-item-selected {
        .pac-item-query {
            color: inherit;
        }
    }

    .pac-item-query::after {
        content: ', ';
    }
}
