.expand-area { 
    position: relative;
    
    &::after {
        bottom: space--s(-1);
        content: "";
        left: space--s(-1);
        position: absolute;
        right: space--s(-1);
        top: space--s(-1);
    }
}