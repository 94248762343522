$c-form__border-width: 2px;
$c-form__padding-bump: 3px;

.form__label {
    display: block;
    font-weight: map-get($base__font-weights, bold);
    margin-bottom: space--s(1/2);
}
.form__label--required::after {
    content: " *";
}
.form__input {
    @include reset(input);
    background-color: map-get($base__colors, 'white');
    border: $c-form__border-width solid map-get($base__colors--overlay, 'white');
    line-height: inherit;
    padding: space--s($addend: $c-form__padding-bump - $c-form__border-width) space($addend: $c-form__padding-bump - $c-form__border-width);
    transition: border-color 250ms;

    &:not(:disabled) {
        &:hover {
            border-color: map-get($base__colors--overlay, 'iron');
        }
        &:focus {
            border-color: map-get($base__colors, 'fall');
        }
    }
}
.form__input--positive {
    border-color: map-get($base__colors, 'white');

    &:not(:disabled) {
        &:hover {
            border-color: map-get($base__colors, 'fall');
        }
    }
}
.form__input--negative {
    background-color: map-get($base__colors, 'black');
    border-color: map-get($base__colors--veillay, 'steel');
    color: map-get($base__colors, 'white');

    &:not(:disabled) {
        &:hover,
        &:focus {
            border-color: map-get($base__colors, 'white');
        }
    }
}
.form__input--join--l {
    border-left: none;
}
.form__input--join--r {
    border-right: none;
}

.form__autocomplete {
    border: $c-form__border-width solid map-get($base__colors, 'fall');
    border-top-color: map-get($base__colors--overlay, 'iron');
    background-color: map-get($base__colors, 'white');
    margin-top: -$c-form__border-width;

    &,
    & > * {
        padding: space--s(1/2, $c-form__padding-bump - $c-form__border-width) space(1/2, $c-form__padding-bump - $c-form__border-width);
    }
}
.form__autocomplete--negative {
    background-color: map-get($base__colors--overlay, 'charcoal');
    border-color: map-get($base__colors, 'white');
    border-top-color: map-get($base__colors--veillay, 'charcoal');
}
.form__autocomplete--join--l {
    border-left-color: transparent;
    border-bottom: none;
    box-shadow: -$c-form__border-width 0 0 0 map-get($base__colors, 'fall'),
                0 $c-form__border-width 0 0 map-get($base__colors, 'fall'),
                -$c-form__border-width $c-form__border-width 0 0 map-get($base__colors, 'fall');
}
.form__autocomplete--join--r {
    border-right-color: transparent;
    border-bottom: none;
    box-shadow: $c-form__border-width 0 0 0 map-get($base__colors, 'fall'),
                0 $c-form__border-width 0 0 map-get($base__colors, 'fall'),
                $c-form__border-width $c-form__border-width 0 0 map-get($base__colors, 'fall');
}

.form__check {
    @include typo--body(s);
    @include space--l(padding-left);
    background-color: map-get($base__colors--overlay, 'white');
    display: block;
    padding: space--s(1/2, 1) space--s();
    padding-left: space--l();
    position: relative;
    transition: background-color 250ms;

    &:not(.form__check--disabled) {
        cursor: pointer;

        &:hover {
            background-color: map-get($base__colors--overlay, 'iron');
        }

        button {
            @include reset('button');

            margin-left: auto;
            line-height: 1;

            &:hover {
                background-color: transparent;
                text-decoration: underline;
            }
        }
    
        &:has(input:not(:checked)) button {
            display: none;
        }
    }
}
.form__check--negative {
    background-color: map-get($base__colors, 'black');
    color: map-get($base__colors, 'white');

    &:not(.form__check--disabled):hover {
        background-color: map-get($base__colors--overlay, 'steel');
    }
}

.form__check__input {
    display: inline-block;
    position: relative;
    top: 2px;

    &:not(.form__check__input--grid) {
        margin-left: space--l(-1);
        text-align: center;
        width: space--l();
    }
}
.form__check__input--grid {
    margin-left: space(-1);
}

.form__input:disabled,
.form__check--disabled {
    opacity: .5;
}
