.dropdown {
    display: inline-block;
    position: relative;
}
.dropdown__body {
    min-width: 100%;
    overflow: hidden;
    pointer-events: none;
    position: absolute;
    z-index: layer(modal);
    
    &.is-hidden {
        display: none;
    }
}
.dropdown__list {
    pointer-events: all;
}
.dropdown__icon,
.dropdown__list {
    transition: transform 250ms;
}
.dropdown.is-active .dropdown__icon {
    transform: scaleY(-1);
}
.dropdown:not(.is-active) .dropdown__list {
    transform: translateY(-100%);
}