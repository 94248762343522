.o-reset--list {
    list-style-type: none;
    padding-left: 0;
}
.o-reset--button {
    @include reset(button);
}
.o-reset--figure {
    @include reset(figure);
}
.o-reset--blockquote {
    @include reset(blockquote);
}