@mixin font-smoothing($active: true, $respect-hi-dppx: true) {
    @if not $active {
        -moz-osx-font-smoothing: grayscale;
         -webkit-font-smoothing: antialiased;
        
        @if $respect-hi-dppx {
            
            @media
            only screen and (min-resolution: 200dpi),
            only screen and (min-resolution: 1.25dppx) {
                                
                -moz-osx-font-smoothing: auto;
                 -webkit-font-smoothing: subpixel-antialiased;
            }
        }
    }
    @else {
        -moz-osx-font-smoothing: auto;
         -webkit-font-smoothing: subpixel-antialiased;
    }
}