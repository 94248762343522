.h0 {
    @include typo--head(0);
}
.h1, h1 {
    @include typo--head(1);
}
.h2, h2 {
    @include typo--head(2);
}
.h3, h3 {
    @include typo--head(3);
}
.h4, h4 {
    @include typo--head(4);
}
.h5, h5 {
    @include typo--head(5);
}
.h6, h6 {
    @include typo--head(6);
}