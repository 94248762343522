.icon {
    fill: currentColor;
    vertical-align: bottom;
    margin-bottom: 0.35em;
}
.icon:not([width]) {
    width: 1em;
}
.icon:not([height]) {
    height: 1em;
}
.icon--s {
    margin-bottom: 0.15em;
}
.icon--l {
    margin-right: 0.5em;
}
.icon--r {
    margin-left: 0.5em;
}