//**
//* Displays a block element in the middle of its container.
//*/
@mixin middle($absolute: false) {
    position: if($absolute, absolute, relative);
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    @if $absolute {
        left: 50%;
        right: auto;
        transform: translate(-50%, -50%);
    }
    @else {
        margin: {
            left: auto;
            right: auto;
        }
    }
}