.o-switch {
    &__flag {
        position: fixed;
        top: 0;
        left: 0;
        clip: rect(1px 1px 1px 1px);
    }
    &__flag:not(:checked) ~ &__body {
        @media screen {
            display: none;
        }
    }
}
