.nav-link {
    border-bottom: 2px solid transparent;
    font-weight: map-get($base__font-weights, 'normal');

    &,
    &:hover,
    &:focus {
        color: map-get($base__colors, 'white');
    }
    &:hover,
    &:focus {
        border-bottom-color: map-get($base__colors, 'white');
    }
}

.nav-link--button {
    font-size: large;
    display: inline-block;
    border-bottom: none;
    
    transition: {
        duration: 250ms;
        property: background-color;
    }

    &.is-active {
        background-color: map-get($base__colors--overlay, 'iron');
    }

    @include media(m-up) {
        font-size: xx-large;
    }
}
@include media(m-to) {
    .nav-link {
        padding: {
            top: space--t();
            bottom: space--t();
        }
    }
    .nav-link__submenu {
        background-color: map-get($base__colors--veillay, 'black');
    }

}
@include media(l-up) {
    .nav-link__subnav {
        position: relative;
    }
    .nav-link__submenu {
        min-width: 10em;
        padding: {
            left: space--s();
            right: space--s();
        }
        position: absolute;
        z-index: layer('modal');
    }
}
