:link,
:visited {
    transition: {
        duration: 250ms;
        property: color, border-bottom-color;
    }
    
    font-weight: map-get($base__font-weights, medium);
    text-decoration: none;
    color: $base__color--link;
    border-bottom: 2px solid mix($base__color--link, map-get($base__colors, 'white'), 75%);
    padding-bottom: 1px;
    
    &:hover,
    &:focus {
        border-bottom-color: $base__color--focus;
    }
    @if $base__color--focus != $base__color--link {
        &:hover,
        &:focus {
            color: $base__color--focus;
        }
    }
}
.b-link--call {
    border: 2px solid $base__color--focus;
    color: $base__color--focus;
    display: inline-block;
    padding: .25em .5em;
    transition-property: color, border-color;
    
    &:hover,
    &:focus {
        color: $base__color--link;
        border-color: $base__color--link;
    }
}
.b-link--reset,
.b-links--reset :link,
.b-link--icon,
.b-links--icon :link {
    border-bottom: none;
    font-weight: inherit;
    padding-bottom: 0;
    transition: none;
    
    &,
    &:hover,
    &:focus {
        color: inherit;
    }
}
.b-link--title,
.b-links--title :link {
    border-bottom: none;
    font-weight: inherit;
    padding-bottom: 0;
    
    @if $base__color--link != $base__color--head {
        &:not(:hover):not(:focus) {
            color: inherit;
        }
    }
}
.b-link--negative,
.b-links--negative :link {
    font-weight: inherit;
    border-bottom-color: rgba(map-get($base__colors, 'white'), .3);
    
    &,
    &:hover,
    &:focus {
        color: map-get($base__colors, 'white');
    }
    &:hover,
    &:focus {
        border-bottom-color: map-get($base__colors, 'white');
    }
}
.b-link--icon,
.b-links--icon :link {
    transition: 250ms opacity;
    
    &:hover,
    &:focus {
        opacity: .7;
    }
}
