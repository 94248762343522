$media__breakpoints: $base__breakpoints !default;

//**
//* media: wraps some rules into a media query
//*
//* e.g.
//*
//* SASS source
//
// .module {
//     color: red;
//     @include media(m) {
//         color: blue;
//     }
//     @include media(l) {
//         color: green;
//     }
// }
//
//* CSS generated
//
// .module {
//     color: red;
// }
// @media only screen and (min-width: 501px) {
//     .module {
//         color: blue;
//     }
// }
// @media only screen and (min-width: 851px) {
//     .module {
//         color: green;
//     }
// }
// 
//*/
@mixin media($media) {
    @if map-has-key($media__breakpoints, $media) and map-get($media__breakpoints, $media) == true {
        @content;
    }
    @else {
        $media__query: ();
        
        @each $media__name in $media {
        	    @if map-has-key($media__breakpoints, $media__name) and type-of(map-get($media__breakpoints, $media__name)) == string {
                $media__query: append($media__query, map-get($media__breakpoints, $media__name), comma);
            }
        }
        @if length($media__query) > 0 {
            @media #{$media__query} {
                @content;
            }
        }
    }
}
//**
//* Generates classes for all the defined breakpoints.
//* You can optionally avoid generating the global one.
//*/
@mixin media-generate($selector-prefix, $generate-global: true, $media-only: null) {
    @if ($generate-global) {
        #{$selector-prefix} {
            @content;
        }
    }
    @each $media-breakpoint-size, $media-breakpoint-rule in $media__breakpoints {
        @if type-of($media-breakpoint-rule) == string {
            @if $media-only == null or index($media-only, $media-breakpoint-size) {
                @media #{$media-breakpoint-rule} {
                    #{$selector-prefix}#{nth($media__selector-suffix, 1)}#{$media-breakpoint-size}#{nth($media__selector-suffix, 2)} {
                        @content;
                    }
                }
            }
        }
        @else if $media-breakpoint-rule == true {
            #{$selector-prefix}#{nth($media__selector-suffix, 1)}#{$media-breakpoint-size}#{nth($media__selector-suffix, 2)} {
                @content;
            }
        }
    }
}