.search-home__head {
  @include media(l-up) {
    flex: 2;
  }
}

.search-home__input {
  display: flex;
}

.search-home__select {
  @include space--s(padding-left, 0.5);
  @include space--s(padding-right, 0.5);
  font-weight: normal;  
}
