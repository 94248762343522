.u-copy {
    &--t {
        @include typo--body(t, $important: true);
    }
    &--s {
        @include typo--body(s, $important: true);
    }
    &--m {
        @include typo--body(m, $important: true);
    }
    &--l {
        @include typo--body(l, $important: true);
    }
}
