@each $fit in (
    fill,
    contain,
    cover,
    none,
    scale-down,
) {
    // examples: .o-object-bg--cover, .o-object-bg--fill
    .o-object-bg--#{$fit} {
        object-fit: $fit;
    }

    // examples: .o-object-bg--cover--tl, .o-object-bg--fill--br
    // we can't generate separate classes for object-fit and object-position and then combine them in the html element
    // becasue this would break the javascript polyfill for browser not supporting object-[fit|position]
    @each $pv in (top, center, bottom) {
        @each $ph in (left, center, right) {
            .o-object-bg--#{$fit}--#{str-slice($pv, 1, 1)}#{str-slice($ph, 1, 1)} {
                object-fit: $fit;
                object-position: $pv $ph;
            }
        }
    }
}

.o-object-bg--cover--video {
    height: auto;
    left: 50%;
    min-height: 100%;
    min-width: 100%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: auto;
}